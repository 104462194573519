/**
 * Vanuit deze module worden alle scripts geladen afhankelijk van de geldende configuratie.
 * Scripts die niet geladen mogen worden onder het actieve consent level worden
 * bewaard zodat ze elders alsnog geladen kunnen worden zodra het consent level wordt verhoogd.
 *
 * Belangrijk implementatie detail: script callbacks worden op basis van hashes in de src url gematcht.
 */
import { NONE } from '../lib/consentLevels.js';
import { DEVELOP, AUTHOR } from '../lib/sessionTypes.js';
import { getPolyfillsHtml } from '../lib/helpers';

/**
 * Named scripts zijn scripts met een hash in de src attribute.
 * Deze functie observeert de head en voegt loading attributes toe aan het document
 * voor elk named script en verwijdert deze zodra het script klaar is met laden.
 * Dit is bijvoorbeeld te gebruiken om via css bepaalde elementen te verbergen zolang
 * een bepaald script nog bezig is met laden. (bijv. html[optimizely-loading])
 *
 * TODO:
 * Huidige omslachtige methode van callbacks koppelen herschrijven naar deze oplossing.
 */
// function handleLoadingAttributesForNamedScripts() {
//     new MutationObserver(function(mutations) {
//         const html = document.documentElement;
//         function attr(verb, name) {
//             html[`${verb}Attribute`](`${name}-loading`, '');
//         }
//         mutations.forEach(function(mutation) {
//             mutation.addedNodes.forEach(function(node) {
//                 // Negeer alles behalve named scripts.
//                 const name = node.src && node.src.split('#', 2)[1];
//                 if (!name) return;
//                 // Zet loading attribute.
//                 attr('set', name);
//                 // Wis loading attribute on error of load.
//                 const cb = () => attr('remove', name);
//                 node.addEventListener('error', cb);
//                 node.addEventListener('load', cb);
//             });
//         });
//     }).observe(document.head, { childList: true });
// }

// Config kan hier nog niet worden geïmporteerd dus wordt deze toegekend vanuit writeScriptTags.
let config, scriptsAwaitingConsent;
// Base voor gedeelde assets.
let commonBaseUrl;
// Script execution modes.
const SYNC = 0, ASYNC = 1, DEFER = 2, execModes = ['', ' async', ' defer'];
// Script tags die naar het document worden geschreven.
const scriptTags = [];

/**
 * Geeft aan vanaf welk consent level en op welke manier een script moet worden uitgevoerd.
 * Indien het script vanwege het consent level niet geladen mag worden wordt deze
 * opgeslagen zodat deze kan worden uitgevoerd zodra het juiste consent level gekozen is.
 * Scripts zonder geldig consent level worden genegeerd. (maakt het mogelijk een script volledig uit te sluiten t.b.v. debugging)
 * @param {*} src
 * @param {*} scriptConsentLevel
 * @param {*} execMode
 */
function useScript(src, scriptConsentLevel, execMode = DEFER) {
    isNaN(scriptConsentLevel)
        // Negeer script zonder geldig consent level.
        ? null
        : scriptConsentLevel > config.consentLevel
            // Script niet direct uitvoeren.
            ? scriptsAwaitingConsent.push([src, scriptConsentLevel])
            // Script direct uitvoeren.
            : scriptTags.push(`<script${execModes[execMode]} src="${src}"></script>`);
}

/**
 * Shortcut functie t.b.v. beter minification resultaat.
 * @param {*} src
 * @param {*} consentLevel
 * @param {*} execMode
 */
function useCommonScript(src, consentLevel, execMode) {
    useScript(commonBaseUrl + src, consentLevel, execMode);
}

/**
 * New Relic configuratie.
 * Gebaseerd op NREUM.info in New Relic snippet.
 * @param {String} hostname
 * @param {String} licenseKey
 * @param {Number} applicationID
 */
function configureNewRelic(accountID, trustKey, agentID, licenseKey, applicationID, beaconHost, agentSrc) {
    global.NREUM = {
        loader_config: { accountID, trustKey, agentID, licenseKey, applicationID },
        info: {
            // NewRelic plakt er zelf "https://"" voor dus die moeten we er hier eerst vanaf halen.
            agent: agentSrc.replace(/^https?:\/\//, ''),
            beacon: beaconHost,
            errorBeacon: beaconHost,
            licenseKey,
            applicationID,
            sa: 1
        }
    };
}

export function writeScriptTags(cfg) {
    config = cfg;
    scriptsAwaitingConsent = config.scriptsAwaitingConsent;
    const baseUrl = config.baseUrl;
    commonBaseUrl = config.common_base_url;
    const path = location.pathname.split('/'); path.shift();
    const isMonitorPage = path[0] === 'monitor';
    // Voorkom directe aanroep door naar de website te redirecten indien geen develop sessie en geen interne forwarder als referrer.
    // Monitor pagina's moeten wel altijd rechtstreeks opvraagbaar zijn. (t.b.v. NewRelic synthetic checks en detectie daarvan)
    config.sessionType !== DEVELOP && location.href.startsWith(baseUrl) && !isMonitorPage && !/^https:\/\/[^/?#]+\.anwb\.nl\//.test(document.referrer) && location.replace('https://www.anwb.nl/');
    // De webwinkel is momenteel uitgesloten van error tracking, real user monitoring en het laden van behaviors.
    // Het is zeer wenselijk om deze centraal aangestuurde aspecten zowel op de website als op de webwinkel
    // te gebruiken maar zolang hierover geen goede afspraken gemaakt zijn en er geen sprake is van een structurele
    // vorm van samenwerking met het webwinkel team wordt dit vanwege praktische bezwaren niet ondersteund.
    const isWebshop = location.hostname.includes('webwinkel');
    if (!isWebshop && !window.fromSombrero) {
        // New Relic configuratie.
        configureNewRelic(
            config.newrelicAccountId,
            config.newrelicTrustKey,
            config.newrelicAgentId,
            config.newrelicLicenseKey,
            config.newrelicApplicationId,
            'bam.eu01.nr-data.net',
            `${commonBaseUrl}/libs/newrelic/nr-spa.js`
        );
        // Zo vroeg mogelijk starten zodat zoveel mogelijk informatie gelogd kan worden.
        useCommonScript(`/libs/newrelic/agent.js`, config.newrelicConsentLevel);
    }
    if (isMonitorPage) {
        path[1] === 'newrelic' && useScript(`${baseUrl}/check-newrelic-fingerprint.js`, NONE);
    } else {
        // Globale initialisatie logica die niet synchroon hoeft te worden uitgevoerd.
        useScript(`${baseUrl}/init-website.js`, NONE);
        // Dit script wordt ook door de webshop gebruikt die een hele eigen HTML implementatie van de huisstijl gebruikt
        // en waar onderstaande scripts dus niet geladen dienen te worden.
        if (!isWebshop) {
            // Alleen html (over)schrijven indien geconfigureerd. (dus bijvoorbeeld niet voor www.anwb.nl)
            config.writeContent && useScript(`${baseUrl}/write-content.js`, NONE);
            // DOM initialisatie die alleen op www van toepassing is (en dus niet webwinkel)
            useScript(`${baseUrl}/init-webpage.js`, NONE);
            // Momenteel is het nog nodig om systemjs hier te laden.
            // Zodra e.e.a. gerefactord is (behaviors worden zoveel mogelijk onafhankelijk gemaakt van systemjs en applicaties zonder systemjs kunnen worden geïnjecteerd)
            // kunnen onderstaande twee scripts lazy worden geladen afhankelijk van behoefte.
            useCommonScript(`/libs/systemjs/s.min.js`, NONE);
            useCommonScript(`/init-components.js`, NONE);
            // LivePerson initialisatie deferred laden zodat de chat na navigeren direct in beeld kan staan.
            useScript(`https://${location.hostname.replace('www', '600.static')}/index.js`, config.livepersonConsentLevel);
        }
        // Optimizely en BlueConic moeten deferred worden uitgevoerd om zichtbare 'redraws' zo veel mogelijk te beperken.
        !window.fromSombrero && useScript(`https://cdn.optimizely.com/js/${config.optimizelyId}.js#optimizely`, config.optimizelyConsentLevel);
        useScript(`https://cdn.blueconic.net/${config.blueconicChannel}.js#blueconic`, config.blueconicConsentLevel);
        useScript('https://www.google-analytics.com/analytics.js', config.gaConsentLevel, ASYNC);
        useScript(`https://www.googletagmanager.com/gtm.js?id=${config.gtmId}&l=dataLayer`, config.gtmConsentLevel, ASYNC);
        // Alleen post-height script laden vanuit frame indien campagne template gebruikt wordt.
        // Dit is een arbitrair bepaalde regel bij gebrek aan een formeel besluit over het embedden van content pages.
        // Voor author sessies wordt het post-height script ook niet geladen aangezien authoring tools hier doorgaans niet correct mee functioneren.
        parent !== window && !AUTHOR && document.documentElement.classList.contains("campaign") && useCommonScript(`/post-height.js`, NONE, ASYNC);
    }
    // MutationObserver activeren voordat script tags worden geschreven.
    // Uitgeschakeld aangezien dit niks meer toevoegt omdat de scripts in kwestie
    // deferred laden en de situatie zich dus niet kan voordoen dat het script nog
    // laadt terwijl de pagina al gerenderd is.
    // De logica bewaren we nog even aangezien deze nog ingezet kan worden als
    // minder omslachtige methode om de script callbacks te regelen.
    // handleLoadingAttributesForNamedScripts();
    // Schrijf script tags naar het document voor scripts die volgens de geldende configuratie uitgevoerd dienen te worden.
    document.write(getPolyfillsHtml(commonBaseUrl) + scriptTags.join(''));
}
