/**
 * Hier wordt de configuratie bepaald.
 * De standaard configuratie wordt beheerd in GitLab via
 * project variabelen die per OTAP omgeving kunnen verschillen.
 * Deze worden bij het deployen geïnjecteerd.
 * Voor lokaal ontwikkelen wordt in plaats daarvan de configuratie
 * gebruikt zoals gespecificeerd in rollup.config.js.
 * 
 * De default configuratie kan voor debug- en test doeleinden overruled
 * worden middels een sessie configuratie.
 * 
 * Configuratie parameters die niet expliciet zijn gespecificeerd krijgen
 * een default waarde toegekend, afhankelijk van de waardes van andere
 * configuratie parameters.
 * 
 * Het devies is om zo min mogelijk configuratie parameters expliciet
 * te specificeren en dit script zijn werk te laten doen voor het invullen
 * van ontbrekende settings met de logische default waarden.
 */
import { DEVELOP, ORGANIC, SYNTHETIC, KIOSK, AUTHOR } from '../lib/sessionTypes.js';
import { retrieveConsentLevel } from './cookieConsent.js';
import { NONE, FUNCTIONAL } from '../lib/consentLevels.js';
import { extend, tryJsonParse, parameterStringToObject, tryJsonParseObject, getCookie, getBrowserFingerprint } from '../lib/utils.js';

// IE 11 ondersteunt geen currentScript en dit is ook niet te polyfillen!
// de babel plugin voor import.meta.url is ook waardeloos...
// Omdat dit script synchroon laadt kunnen we hier terugvallen op document.scripts
// maar verder dient nergens currentScript gebruikt te worden!
export const currentScript = (document.currentScript || document.scripts[document.scripts.length - 1]);
const currentScriptSrc = currentScript.src;

let config, sessionStorage;

/**
 * Indien niet organisch of cookies uitgeschakeld of doNotTrack actief: FUNCTIONAL.
 * Anders het door de gebruiker geselecteerde consent level dan wel NONE retourneren.
 */
function getDefaultConsentLevel() {
    return config.sessionType !== ORGANIC // Niet-organische sessies.
        || sessionStorage.constructor !== Storage // Gebruik van Storage shim verraadt dat cookies uitgeschakeld zijn.
        || (navigator.doNotTrack || window.doNotTrack || navigator.msDoNotTrack) === '1' // "Niet volgen" verzoek gehoorzamen.
            ? FUNCTIONAL
            : retrieveConsentLevel(config.cookiePolicyRevision) || NONE;
}

/** Detecteer session type op basis waarvan de default settings worden bepaald. */
function detectSessionType() {
    // Detecteer de webzuilen in de ANWB winkels.
    if (config.kioskUserAgentRegExp && RegExp(config.kioskUserAgentRegExp).test(navigator.userAgent)) return KIOSK;
    // Heeft de huidige browser dezelfde fingerprint als die van de New relic synthetic checks?
    const isProbablyNewRelic = getBrowserFingerprint() === config.newrelicBrowserFingerprint;
    // Detecteer diverse bots.
    if (window.Cypress || isProbablyNewRelic || config.syntheticUserAgentRegExp && RegExp(config.syntheticUserAgentRegExp).test(navigator.userAgent)) return SYNTHETIC;
    // Detecteer authoring tools: BlueConic simulator, Hippo preview mode, Hippo Channel Manager.
    if (window.name === 'bc_simulator' || location.hostname.startsWith('preview') || location.pathname.startsWith('/site/_cmsinternal/')) return AUTHOR;
    // Niks speciaals aan de hand? Dan is het gewoon organisch verkeer.
    return ORGANIC;
}

/**
 * Pas document configuratie toe indien van toepassing.
 * Het gaat om settings die per url en/of url segment beheerd kunnen
 * worden (bijvoorbeeld in het CMS) en naar het document worden uitgeschreven.
 * Dit is momenteel nog niet geïmplementeerd in het CMS aangezien nog niet
 * helder is op welke manier we dit willen gaan aanbieden.
 */
function applyDocumentConfig() {
    // Momenteel wordt de document configuratie gelezen uit de hash in het src attribuut van
    // het huidige script maar dit zou ook prima op een andere manier kunnen.
    // De enige voorwaarde is dat de configuratie niet na de script tag mag staan aangezien het
    // script synchroon moet worden uitgevoerd.
    const match = currentScriptSrc.match(/#(.*)/);
    extend(config, match && getConfigFromParameterString(match[1]));
}

function getConfigFromParameterString(parameterString) {
    return tryJsonParseObject(parameterStringToObject(parameterString)) || null;
}

/**
 * Kent logische configuratie defaults toe voor ontbrekende configuratie settings.
 */
function applyDefaultConfig() {
    // Default toekennen indien huidige waarde null (of undefined) is.
    function applyDefault(key, defaultValue) {
        const value = config[key];
        if (value == null) config[key] = defaultValue;
    }
    // Bepaal default config settings.
    const defaults = {
        // Base url relatief aan het huidige script.
        baseUrl: currentScriptSrc.match(/^(.+)\/index.js/)[1],
        consentLevel: getDefaultConsentLevel(),
        signedIn: parseInt(getCookie('login-expires'), 10) > Date.now(),
        cookiePolicyRevision: 0,
        experiments: {}
    };
    // Ken default waarden toe voor ontbrekende config settings.
    for (const key in defaults) applyDefault(key, defaults[key]);
}

/**
 * Pas de configuratie toe zoals opgeslagen in de sessie.
 */
function applySessionConfig() {
    extend(config, tryJsonParse(sessionStorage.getItem('config')));
}

/**
 * Schrijft sessie configuratie.
 */
function setSessionConfig(config) {
    sessionStorage.setItem('config', JSON.stringify(config));
    return config;
}

/**
 * Kijkt of er een config is meegegeven in de hash.
 * Dit is bedoeld voor het overrulen van de configuratie voor troubleshooting,
 * lokaal ontwikkelen/testen en regressie-, integratie- en monitorscripts.
 * 
 * ##foo=bar betekent:  interpreteer alles na ## als session config.
 * ###foo=bar betekent: interpreteer alles na ### als eenmalige config.
 * 
 * Vervolgens wordt bepaald of deze actie geoorloofd is gegeven de geldende configuratie.
 * Dit om te voorkomen dat dergelijke url's mogelijk 'in het wild' gebruikt gaan worden.
 * Niet dat dit op zichzelf kwaad kan of een security risico vormt maar i.c.m. een
 * phishing/clickjacking/xss/xsrf poging zouden hackers in theorie hun sporen kunnen verbergen
 * als ze via de url in staat zouden zijn om bijvoorbeeld de logging uit te schakelen hetgeen
 * detectie en analyse zou bemoeilijken.
 * 
 * Het is altijd mogelijk om via de console (of via js bookmarklets zolang de CSP dit toelaat)
 * de sessie configuratie te schrijven. Door sessionType of allowHashParameters te overschrijven
 * kan er vervolgens alsnog gebruik worden gemaakt van configuratie via de hash.
 */
function applyDebugConfig() {
    const getConfigHashMatch = () => location.hash.match(/^(###?)(.*)/);
    // Voor het gemak ook een hashchange listener definiëren.
    window.addEventListener('hashchange', e => getConfigHashMatch() && location.reload());
    // Hebben we een match?
    const configHashMatch = getConfigHashMatch();
    // Zo niet, gelijk stoppen.
    if (!configHashMatch) return;
    // Hulpfunctie om via de browserconsole of een js bookmarklet de
    // sessie configuratie te overschrijven t.b.v. testing/debugging/troubleshooting.
    // Bedoeld voor situaties waarin debug config via de url niet ondersteund wordt.
    global.loadSessionConfig = function (sessionConfig) {
        setSessionConfig(sessionConfig);
        location.reload();
    }
    // Hash configuratie lezen.
    const hashConfig = getConfigFromParameterString(configHashMatch[2]);
    if (Object.keys(hashConfig).length) {
        // Hash parameters worden default alleen toegestaan voor niet organische sessies.
        const allowHashParameters = typeof config.allowHashParameters === 'boolean' ? config.allowHashParameters : config.sessionType !== ORGANIC;
        // Kijken of het wel de bedoeling is om config via de hash te accepteren.
        if (allowHashParameters) {
            const writeToSession = configHashMatch[1].length === 2;
            // Hash configuratie toepassen en naar session storage schrijven indien het session config betreft.
            extend(config, writeToSession ? setSessionConfig(hashConfig) : hashConfig);
            // Informeren via de console.
            console.info(`Hash parameters toegepast${writeToSession ? ' en in de sessie opgeslagen' : ''}:`, hashConfig);
        } else {
            console.error(`Het gebruik van hash parameters is niet toegestaan voor de huidige configuratie. Gebruik loadSessionConfig({...}).`);
        }
    }
    // Actieve configuratie in console tonen.
    console.info('Actieve configuratie:', config);
}

export function initializeConfig(env) {
    // sessionStorage zodanig lezen dat we geen last hebben van security errors op iOS 9 indien DOM storage disabled is.
    sessionStorage = global._sessionStorage || global.sessionStorage;
    // Neem env als basis voor config.
    config = env;
    // tryJsonParseObject t.b.v. de door de GitLab deployer geïnjecteerde 'platte' JSON.
    tryJsonParseObject(config);
    // Document configuratie toepassen.
    // Configuratie settings die door web redacteuren beheerd worden, in-/uitschakelen van bepaalde meetscripts etc.
    applyDocumentConfig();
    // Sessie configuratie toepassen.
    applySessionConfig();
    // sessionType detecteren indien config.sessionType hier nog niet bekend is.
    // Deze is vereist voor applyDebugConfig en applyDefaultConfig.
    if (typeof config.sessionType !== 'number') config.sessionType = detectSessionType();
    // Lees configuratie overrides voor debug doeleinden.
    applyDebugConfig();
    // Defaults voor de settings die nog geen waarde hebben worden achteraf toegepast.
    // Dit omdat defaults afhankelijk moeten kunnen zijn van overige settings.
    applyDefaultConfig();
    // scriptsAwaitingConsent op config beschikbaar maken t.b.v. koppelen callbacks.
    config.scriptsAwaitingConsent = [];
    // console.debug overschrijven als het geen develop sessie is.
    if (config.sessionType !== DEVELOP) console.debug = () => {};
    // Maak config globaal toegankelijk voor de overige initialisatie scripts aangezien er geen module loader gebruikt wordt in dit stadium.
    return global.config = Object.freeze(config);
}
