/**
 * Storage class waarvan de interface overeenkomt met die van de globale DOM Storage class.
 * Gebruikt geen class syntax meer omdat daar babelHelpers voor nodig zijn en het is niet
 * efficiënt om die op dit punt in de initialisatie van de pagina al beschikbaar te maken.
 */

// WeakMap gebruiken voor private items zodat we geen
// babelHelpers nodig hebben maar toch encapsulation bereiken.
const items = new WeakMap();

function MemoryStorage() {
	this.clear();
}

MemoryStorage.prototype = {

	key(n) {
		return Object.keys(items.get(this))[n];
	},

	setItem(key, value) {
		// Waarde altijd als string schrijven conform native DOM Storage.
		items.get(this)[key] = '' + value;
	},

	getItem(key) {
		return items.get(this)[key];
	},

	removeItem(key) {
		delete items.get(this)[key];
	},

	clear() {
		items.set(this, {});
	},

	// Forward calls voor hasOwnProperty naar het items object.
	// I.c.m. het freezen van instanties zorgt dit ervoor dat we geen overkill Proxy-gebaseerde polyfill hoeven te gebruiken.
	hasOwnProperty(key) {
		return items.get(this).hasOwnProperty(key);
	},

	get length() {
		return Object.keys(items.get(this)).length;
	}

};

/**
 * Installeert een in-memory shim voor DOM Storage indien deze niet toegankelijk is vanwege browser security overrides.
 */
export function installStorageShimIfNeeded(globalName) {
	try {
		// Faalt met een SecurityError indien geblokkeerd en met een TypeError indien niet gedefinieerd.
		// De is nodig om te voorkomen dat de DCE van Rollup de code elimineert bij gebrek aan side-effects.
		return 'key' in global[globalName];
	} catch (e) {
		// Bevries het object om te voorkomen dat expando-properties ondersteund hoeven te worden via Proxy.
		const storage = Object.freeze(new MemoryStorage());
		try {
			// Overschijf het native object.
			Object.defineProperty(global, globalName, { value: storage });
		} catch (e) {
			// Schrijf het object onder een alternative naam indien het overschrijven faalt. (iOS 9)
			// Kan als volgt gebruik van gemaakt worden: const myGlobalName = global._myGlobalName || global.myGlobalName;
			global[`_${globalName}`] = storage;
		}
	}
}
