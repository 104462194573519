/**
 * Deze module bevat generieke hulpfuncties.
 * Project-specifieke hulpfuncties horen thuis in de helpers module.
 * 
 * Theoretisch zouden al deze functies moeten worden binnengehaald als external dependencies.
 */

/**
 * Beperkt alternatief voor Object.assign.
 * Een volledige Object.assign polyfill wordt separaat deferred ingeladen.
 * Om toch alvast tijdens de initialisatie hetzelfde patroon te kunnen
 * gebruiken kan deze beperktere oplossing worden gebruikt.
 */
export const extend = Object.assign || function (a, b) {
    for (const key in b) a[key] = b[key];
    return a;
}

/**
 * Interpoleert een string conform JS template string notatie.
 * Let op! Evalueert geen code!
 * Accepteert enkel namen die als key in het meegegeven object moeten voorkomen.
 * @param {string} string - Te interpoleren string.
 * @param {*} object - Te vervangen variabelen.
 */
export function interpolate(string, object) {
    const rx = /\${[^}\r\n]*}/g, matches = string.match(rx);
    if (!matches) return string;
    const strings = string.split(rx);
    return matches.map(function (placeholder, i) {
        const key = placeholder.slice(2,-1);
        return strings[i] + (key in object ? object[key] : '');
    }).join('') + strings[strings.length - 1];
}

/**
 * Voer een functie uit voor elke key/value combi van een object.
 * @param {*} object 
 * @param {*} fn 
 */
export function forEachProperty(object, fn) {
    for (const key in object) fn(key, object[key]);
}

/**
 * Schrijf key/value pairs als attributes.
 * @param {*} node 
 * @param {*} attrs 
 */
export function assignAttributes(node, attrs) {
    forEachProperty(attrs, (key, value) => node.setAttribute(key, value));
    return node;
}

/**
 * Past een polyfill toe indien de functie niet native beschikbaar is.
 * @param {*} object - Het object waarop de functie dient te bestaan.
 * @param {*} functions - De functies die eventueel gepolyfilld moet worden.
 */
export function polyfill(object, functions) {
    for (const fnName in functions) {
        // Check expliciet of het een functie is.
        // Bijvoorbeeld van belang in geval van CustomEvent in IE.
        if (typeof object[fnName] !== 'function') object[fnName] = functions[fnName];
    }
}

export function injectStylesheet(href) {
    return document.head.appendChild(Object.assign(document.createElement('link'), {
        rel: 'stylesheet',
        href: href
    }));
}

/**
 * De babel plugin voor import.meta syntax support is nogal naïef daar waar het IE 11 betreft.
 * De plugin baseert alle script url's op document.baseURI indien document.currentScript niet wordt ondersteund!
 * Deze functie werkt om de beperkingen van deze plugin heen maar zal helaas ook niet in alle gevallen correct werken.
 * Gelieve niet te gebruiken indien de naam van het script niet uniek genoeg is!
 */
export function findCurrentScript() {
    return document.currentScript || document.querySelector(`script[src$="${new URL(import.meta.url).pathname.match('[^\/]+$')[0]}"]`);
}

export function getMultiSelector(before, array, after) {
    return before + array.join(after + ', ' + before) + after;
}

/**
 * Zet een string van key-value pairs om naar een object.
 * Notatie conform querystring: "key1=value1&key2=value2"
 * @param {String} parameterString 
 */
export function parameterStringToObject(parameterString) {
    if (!parameterString) return {};
    parameterString = decodeURI(parameterString);
    const kvPairs = parameterString.split('&'), obj = {};
    kvPairs.forEach(function (kvPair) {
        const kv = kvPair.split('='), k = kv[0], v = kv[1];
        obj[k] = tryJsonParse(v, v);
    });
    return obj;
}

/**
 * 
 * @param {Object} object 
 * @param {String} value 
 */
export function getKeyByValue(object, value) {
    for (const key in object) if (object[key] === value) return key;
}

export function getBrowserFingerprint() {
    return [
        navigator.vendor,
        navigator.platform,
        navigator.hardwareConcurrency,
        navigator.doNotTrack,
        screen.width,
        screen.height,
        screen.availWidth,
        screen.availHeight,
        screen.colorDepth,
        screen.pixelDepth,
        screen.availLeft,
        screen.availTop
    ].join('|');
}

/**
 * Lees cookie waarde.
 * @param {String} name 
 */
export function getCookie(name) {
    const match = document.cookie.match(new RegExp('(?:^|;\\s?)' + encodeURIComponent(name) + '=([^;]*)'));
    return match && decodeURIComponent(match[1]);
}

/**
 * Schrijf een cookie.
 * Geen volledige implementatie: slechts genoeg voor huidige use-cases.
 * @param {String} name 
 * @param {*} value 
 * @param {Date} expiresDate 
 * @param {String} domain 
 * @param {String} path 
 */
export function setCookie(name, value, expiresDate, domain, path) {
    document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)};expires=${expiresDate.toUTCString()};domain=${domain};path=${path};secure;samesite=strict`;
}

/**
 * Veilig JSON parsen.
 * Retourneert fallbackValue indien value geen geldige JSON is.
 * @param {*} value 
 * @param {*} fallbackValue 
 */
export function tryJsonParse(value, fallbackValue) {
    try {
        return JSON.parse(value);
    } catch (e) {
        return fallbackValue;
    }
}

/**
 * Probeert elke waarde te parsen als JSON en overschijft deze met het resultaat indien succesvol.
 * Daar waar JSON.parse niet lukt wordt de oorspronkelijke waarde behouden.
 * @param {*} object 
 */
export function tryJsonParseObject(object) {
    for (const key in object) {
        const value = object[key];
        object[key] = value === '' ? undefined : tryJsonParse(value, value);
    }
    return object;
}
/**
 * Utility functions die gebruikt worden door de modules binnen deze map.
 */

/**
 * Injecteert een script waardoor deze asynchroon wordt uitgevoerd.
 * Door forceOrderedExecution op false te zetten wordt het script direct uitgevoerd zodra het geladen is
 * en wordt er dus niet gewacht tot eerder geïnjecteerde asynchrone scripts zijn uitgevoerd.
 * @param {String} src 
 * @param {Boolean} forceOrderedExecution 
 */
export function executeScriptAsync(src, forceOrderedExecution) {
    return document.head.appendChild(Object.assign(document.createElement('script'), {
        src: src,
        async: forceOrderedExecution === false
    }));
}

/**
 * Retourneert een iframe element behorende bij het meegegeven window object.
 * Deze functie is nodig vanwege het feit dat window.frameElement niet toegankelijk
 * is in geval van cross-origin frames.
 * @param {Window} window 
 */
function findIFrameElementByWindow(window) {
    if (!window) return null;
    // TODO: Optimize by tagging iframe elements with an attribute once associated with a window.
    const frames = document.querySelectorAll('iframe'), l = frames.length;
    for (let n = 0; n < l; n++) {
        const f = frames[n];
        if (f.contentWindow === window) return f;
    }
}

let getIFrameElementByWindow;
if (global.WeakMap) {
    // Gebruik een WeakMap voor memoization.
    const framesMap = new WeakMap();
    getIFrameElementByWindow = function (window) {
        return window && (framesMap.get(window) || framesMap.set(window, findIFrameElementByWindow(window)).get(window)) || null;
    };
} else {
    // Geen memoization voor browsers zonder WeakMap.
    getIFrameElementByWindow = findIFrameElementByWindow;
}
export { getIFrameElementByWindow };

export function escapeHtml(unsafe) {
    return unsafe
        .replace(/&/g, `&amp;`)
        .replace(/</g, `&lt;`)
        .replace(/>/g, `&gt;`)
        .replace(/"/g, `&quot;`)
        .replace(/'/g, `&#039;`);
}

/**
 * AdBocker detectie.
 * Getest met AdBlock voor Chrome en Firefox en Ad-Aware Ad Block voor Firefox.
 * Werkt dus mogelijk niet voor alle combinaties van browsers en AdBlockers.
 * @param {*} callback 
 */
export function detectAdBlocker(callback) {
    const parent = document.documentElement, ad = parent.appendChild(document.createElement('div'));
    // Onderstaande className triggert de adblocker.
    ad.className = 'afs_ads';
    function ready(b) {
        parent.removeChild(ad);
        callback(b);
    }
    // Geef de adblocker de gelegenheid om te laden.
    const detectionEnd = Date.now() + 3000;
    function detect() {
        const display = getComputedStyle(ad).display, detected = display === '' || display === 'none';
        // Het kan even duren voordat het adblocker stylesheet is geladen
        // dus blijven we een paar keer checken of het element inmiddels verborgen is.
        detected || Date.now() >= detectionEnd
            ? ready(detected)
            : setTimeout(detect, 100);
    }
    detect();
}
