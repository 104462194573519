import { polyfill } from '../lib/utils';

// Een minimale set van String polyfills die tijdens initialisatie al nodig zijn.
polyfill(String.prototype, {
    startsWith(s, i = 0) {
        return this.indexOf(s, i) === i;
    },
    endsWith(s, l) {
        return this.indexOf(s, (l < this.length ? l : this.length) - s.length) > -1;
    },
    includes(s, i) {
        return this.indexOf(s, i) > -1;
    }
});
