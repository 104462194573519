/**
 * Dit script dient gebouwd te worden met rollup.
 * Het resulterende script fungeert als bootstrapper en draagt
 * zorg voor correcte aanroep en gebruik, het bepalen van de
 * configuratie en het uitvoeren van andere scripts.
 *
 * Dit script dient synchroon te worden uitgevoerd omdat er deferred
 * scripts naar het document geschreven moeten kunnen worden.
 * Om die reden is het belangrijk dat dit script zo compact mogelijk
 * blijft zodat de parser zo kort mogelijk geblokkeerd hoeft te blijven.
 * Alle code die niet persé synchroon uitgevoerd hoeft te worden moet
 * dus vanuit een ander script deferred of asynchroon worden uitgevoerd.
 *
 * Er is een aantal redenen waarom alle scripts vanuit dit script naar
 * het document worden geschreven (zie writeScriptTags.js) en niet
 * rechtstreeks in de HTML zijn opgenomen:
 *
 * 1. De web pagina's worden gecache't waardoor het nu niet mogelijk is
 *    om betrouwbaar wijzigingen door te voeren in het laden van scripts
 *    hetgeen makkelijk tot fouten tijdens initialisatie van de pagina
 *    kan leiden.
 * 2. We beschikken op het moment niet over een geautomatiseerd proces
 *    waarmee het uitschrijven van script tags door het CMS aangestuurd
 *    kan worden vanuit de frontend release pipeline. Dit maakt het dus
 *    erg omslachtig en foutgevoelig om hierin wijzigingen door te voeren.
 * 3. Bepaalde scripts moeten al dan niet geladen worden afhankelijk van
 *    clientside bepaalde voorwaarden.
 */
import { initializeConfig, currentScript } from './initializeConfig';
import { writeScriptTags } from './writeScriptTags';
import env from './env';
import { installStorageShimIfNeeded } from './storageShim';
// T.b.v. startsWith.
import './minimalStringPolyfills';

/**
 * Voorkom onjuiste aanroep:
 * Synchrone aanroep is vereist omdat andere synchroon of deferred uit te
 * voeren scripts naar het document geschreven moeten kunnen worden.
 * Idealiter worden deze tags door het CMS uitgeschreven maar we beschikken op het moment
 * van schrijven niet over een robuuste oplossing om dit gestructureerd aan te kunnen sturen.
 * Bovendien moet het uitvoeren van bepaalde scripts afhankelijk kunnen zijn van clientside
 * variabelen zoals bijvoorbeeld het tracking consent level of het type browser.
 */
function preventIncorrectExecution() {
    if (document.readyState !== 'loading') throw new Error('Dit script dient synchroon te worden uitgevoerd.');
}

/**
 * Bepaalde bezoekers slaan de webpagina lokaal op om deze op een later tijdstip te openen.
 * Dergelijk gebruik wordt echter niet door ons ondersteund en dus voorkomen we deze situatie
 * door de gebruiker door te verwijzen naar de online versie van de opgeslagen pagina.
 */
function preventLocalAccess() {
    if (location.protocol.startsWith('file')) {
        document.open('text/html', 'replace');
        document.write(`<a href="${document.querySelector('link[rel="canonical"]').href}">Bekijk deze pagina online</a>`);
        document.close();
        throw new Error('Niet ondersteund');
    }
}

/**
 * Zet een loading attribute op het html element zolang de pagina nog niet geladen is zodat
 * er css attribute selectors gebruikt kunnen worden voor loading-specifieke styling.
 * Dit wordt bijvoorbeeld gebruikt om te voorkomen dat achtergrond afbeeldingen met hogere prioriteit worden gedownload dan scripts.
 */
function deprioritizeBackgroundImageLoading() {
    // De webwinkel laadt dit script vanuit de body hetgeen te laat is om deze optimalisatie toe te passen, vandaar de parent check.
    if (currentScript.parentElement === document.head) {
        const html = document.documentElement;
        html.setAttribute('loading', '');
        // Activeer de background images pas weer bij het DOMContentLoaded event.
        document.addEventListener('DOMContentLoaded', function () {
            html.removeAttribute('loading');
        });
    }
}

/*
Sombrero loads this script for any applications on a Sombrero page that is still running this stack.
This causes issues (e.g. double cookie banners). We allow Sombrero to pass a data attribute to
don't load certain parts
*/
window.fromSombrero = document.currentScript.dataset.fromSombrero === 'true'

preventIncorrectExecution();
preventLocalAccess();
deprioritizeBackgroundImageLoading();
installStorageShimIfNeeded('sessionStorage');
installStorageShimIfNeeded('localStorage');
writeScriptTags(initializeConfig(env));
