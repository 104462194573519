/**
 * De verschillende consent levels die we hanteren voor het gebruik van cookies en aanverwante technologieën.
 */
export const            // De consent levels worden als volgt geïnterpreteerd:
    NONE        = 0,    // - Geen expliciete keuze bekend. (zelfde als FUNCTIONAL maar dan impliciet)
    FUNCTIONAL  = 1,    // - Vereist niveau voor correct functioneren van de website.
    LIMITED     = 2,    // - Personalisatie beperkt tot de website.
    FULL        = 3;    // - Volledige personalisatie. (dus ook toegang tot externe tracking gegevens)

// De naam voor het cookie waarin het gekozen consent level wordt opgeslagen.
export const COOKIE_NAME = 'cookie-policy-agreement';
