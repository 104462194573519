/**
 * Deze module bevat project-specifieke hulpfuncties.
 * Generieke hulpfuncties horen thuis in de utils module.
 */
import { forEachProperty, polyfill } from './utils';

// Sandbox voor vertrouwde content.
export const laxSandbox = 'allow-forms allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts allow-top-navigation';

/**
 * Retourneert de origin gegeven het projectId.
 *
 * @param {*} projectId - id|id@slug|hostname-zonder-punt:port|hostname-met-punt
 * id@slug is nog niet geïmplementeerd aangezien dit nog niet door de deployer wordt ondersteund.
 */
export function projectIdToOrigin(projectId) {
    return projectId === '0' ? location.origin : 'https://' + projectId + (/[\.:]/.test(projectId) ? '' : '.' + getStaticHost());
}

/**
 * Retourneert de standaard host waaronder subdomeinen voor applicaties beschikbaar zijn.
 */
function getStaticHost() {
    // Hele omslachtige methode vanwege alle verschillende situaties waarin dit moet werken.
    const firstStaticScript = document.querySelector('script[src^="https://static"][src*=".anwb.nl"],script[src^="https://www.static"]');
    return firstStaticScript ? new URL(firstStaticScript.src.replace('www.', '')).host : 'static-ontw.anwb.nl';
}

let previousExperiments;
let setExperimentAttributesId;

/**
 * Zet attributen op het html element op basis waarvan css geschreven kan worden t.b.v. experimenten / a-b tests.
 * { experimentId: variant }
 * Verwijderd de attributen die met vorige call naar deze methode erop zijn gezet.
 * @param {*} experiments
 */
export function setExperimentAttributes(experiments) {
    cancelAnimationFrame(setExperimentAttributesId);

    // Groepeer DOM updates om onnodige rerenders te voorkomen
    setExperimentAttributesId = requestAnimationFrame(() => {
        const html = document.documentElement;

        // Verwijder vorige experimenten, tenzij ze hetzelfde zijn als huidige experimenten
        if (previousExperiments) {
            forEachProperty(previousExperiments, (key, value) =>
                experiments[key] !== value && html.removeAttribute(`experiment-${key}`)
            );
        }

        // Zet nieuwe experimenten attributen, tenzij ze hetzelfde zijn als oude experimenten
        forEachProperty(experiments, (key, value) =>
            (!previousExperiments || previousExperiments[key] !== value) &&
            html.setAttribute(`experiment-${key}`, value)
        );

        // Onthoudt experimenten voor volgende keer
        previousExperiments = Object.assign({}, experiments);
    });
}

// Ondersteuning voor fossiele browsers. (alle versies van IE en zwaar verouderde versies van overige browsers)
export function getPolyfillsHtml(commonBaseUrl) {
    const polyfills = [];
    function p(object, fnName, scriptName) {
        typeof object[fnName] !== 'function' && polyfills.push(scriptName || fnName);
    }
    // TODO: eigen optimale core-js bundle(s) bouwen.
    // Proxy wordt hier als feature detection gebruikt om IE en Safari 9 te targeten.
    p(global, 'Proxy', 'core-js-bundle/minified'); // IE/Firefox17/Chrome48/Safari9.1
    // De Proxy polyfill is niet vereist voor babelHelpers maar voor het geval deze elders
    // wordt gebruikt is het zaak dat de polyfill vóór babelHelpers wordt geladen.
    p(global, 'Proxy'); // IE/Firefox17/Chrome48/Safari9.1
    p(global, 'babelHelpers');
    p(global, 'regeneratorRuntime');
    p(global, 'CustomEvent', 'dom'); // IE
    p(global, 'fetch'); // IE/Edge13/Firefox38/Chrome41/Safari10
    // IntersectionObserver polyfill is benodigd voor lazy-loading support voor o.a. IE 11.
    p(global, 'IntersectionObserver'); // IE/Firefox54/Safari12/Chrome50
    // Minimale polyfill voor isIntersecting voor Edge 15.
    // Zie: https://github.com/w3c/IntersectionObserver/issues/211
    if (global.IntersectionObserverEntry && !('isIntersecting' in IntersectionObserverEntry.prototype)) {
        Object.defineProperty(IntersectionObserverEntry.prototype, 'isIntersecting', { get: () => this.intersectionRatio > 0 });
    }
    // Deze polyfill is benodigd voor Firefox 57 en Safari 11.
    // Conform de spec test van: https://www.npmjs.com/package/promise.prototype.finally.
    global.Promise && polyfill(Promise.prototype, {
        finally(fn) {
            return this.then(r => (fn(), r), e => {fn(); throw e; });
        }
    });
    const [open, close] = [`<script defer src="${commonBaseUrl}/polyfills/`, '.js"></script>'];
    return open + polyfills.join(close + open) + close;
}