/**
 * We onderscheiden een aantal verschillende session types.
 * Op basis daarvan worden logische default config settings bepaald.
 * Het heeft sterk de voorkeur om niet rechtstreeks functionele keuzes te maken
 * op basis van session type maar altijd indirect via een config setting.
 * Zodoende blijft het overzichtelijk welke gedragingen er worden onderscheiden
 * en kan dit gedrag makkelijk overruled worden voor debug doeleinden en later
 * worden aangepast.
 */
export const            // De session types worden als volgt geïnterpreteerd:
    DEVELOP     = 0,    // Modus t.b.v. ontwikkelen/testen.
    ORGANIC     = 1,    // Reguliere bezoekers.
    AUTHOR      = 2,    // Sessies die omwille van authoring worden geïnitieerd zoals de Hippo channel manager en preview modus en de BlueConic simulator.
    SYNTHETIC   = 3,    // Bot verkeer zoals test clients, crawlers en monitoring agents.
    KIOSK       = 4;    // Webzuilen in de fysieke ANWB winkels.
