/**
 * Wat er precies valt onder de verschillende niveaus wordt bepaald door de privacy officer
 * en wordt vastgelegd in de privacy policy.
 */
import { getCookie, tryJsonParse } from '../lib/utils.js';
import { NONE, COOKIE_NAME } from '../lib/consentLevels.js';

function retrieveAgreement() {
    return tryJsonParse(getCookie(COOKIE_NAME));
}

export function retrieveConsentLevel(cookiePolicyRevision) {
    const agreement = retrieveAgreement();
    return agreement && agreement.revision === cookiePolicyRevision ? agreement.consentLevel : NONE;
}
